import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import '../Auth/auth.scss';
import PWMarkdown from '../../components/common/PWMarkdown';
import ArrowRight from '../../images/icons/right-chevron-dark.png';
import ShareIcon from '../../images/icons/share.png';
import { api, getPlainText } from '../../../utils';
import SocialShare from '../../components/common/SocialButtons';
import LikeIcon from '../../images/icons/like.png';
import LikeIconActive from '../../images/icons/like-active.png';

export const WTCard = ({ data, fetchBookmarks, disableRemove=false, fullWidth=false, user, bookmarks, location }) => {

    const [openShare, setOpenShare] = useState(false)
    const description = data.acf_wise_tip.tip;

    if (!data || data.status != 'publish') {
        return null;
    }

    const bookmarkTip = (slug) => {
        const fData = {
            type: 'wise-tip',
            slug
        }
        api.post('/api/v1/user-bookmark/toggle', fData)
        .then(resp => {
            if (resp && resp.success) {
                fetchBookmarks();
            } else {
                alert("Something went wrong. Please try again later!");
            }
        });
    }

    const getDomains = (data) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let domains = [];
        data.nodes.forEach(item => {
            if (!item.parentDatabaseId) {
                return;
            }
            domains.push(item.name)
        });

        return domains.join(', ');
    }

    const getParentDomain = (data) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let domains = [];
        data.nodes.forEach(item => {
            if (item.parentDatabaseId) {
                return;
            }
            domains.push(item.name)
        });

        return domains.join(', ');
    }

    const getCategories = (data, key) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let categoryList = [];
        data.nodes.forEach(item => {
            categoryList.push(item[key])
        });

        return categoryList.join(', ');
    }

    const bookmarkRemove = (slug) => {
        const fData = {
            type: 'wise-tip',
            slug
        }
        api.post('/api/v1/user-bookmark/remove', fData)
        .then(resp => {
            if (resp && resp.success) {
                fetchBookmarks();
            } else {
                alert("Something went wrong. Please try again later!");
            }
        });
    }

    return (
        <>
        {!disableRemove && (
            <div className="pw-swt-remove-main" style={{ alignSelf: 'flex-start'}}>
                <div className="pw-swt-remove w3-right w3-hide-medium w3-hide-large"
                    style={{ cursor: "pointer" }}
                    onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        bookmarkRemove(data.slug);
                    }}
                >
                    x Remove
                </div>
            </div>
        )}
        <div className={`${fullWidth ? 'w3-col s12' : 'w3-col l4 m6 s12'}`} style={{ textDecoration: 'none' }}>            
            <div className={`pw-swt-block ${data.acf_wise_tip.type === 'coreFinding' ? 'swt-padding' : ''} `} 
                style={(data.acf_wise_tip.type === 'coreFinding') ? { border: `2px solid ${data.acf_wise_tip.themeColor}`} : {}}>
                <Link to={`/${(data.acf_wise_tip.type === 'coreFinding') ? 'core-finding' : 'wise-tip'}/${data.slug}`}>  
                    <div className="w3-row pw-wt-header" style={{ borderBottomColor: `${data.acf_wise_tip.themeColor}` }}>
                        <div className="pw-wt-title" style={{ color: `${data.acf_wise_tip.themeColor}` }}>
                            {getParentDomain(data.domains)}
                        </div>
                        <div className="pw-wt-subtitle" style={{ color: `${data.acf_wise_tip.themeColor}` }}>
                            {getDomains(data.domains)}
                        </div>
                    </div>
                        <div className="w3-row pw-wt-body">
                            <div className="w3-col s12">
                                <div className="pw-wt-body-desc pw-swt-desc">
                                    <div>
                                        {getPlainText(data.acf_wise_tip.tip)}
                                    </div>
                                <div className="w3-hide-medium w3-hide-large"><img className="pw-arrow-right w3-margin-top w3-margin-left" src={ArrowRight} /></div>
                                </div>
                            </div>
                        </div>
                    <div className="w3-row pw-wt-footer">
                        <div className="w3-col s12">
                            <div className="pw-swt-footer-left">
                                {(data.acf_wise_tip.type === 'wiseTip') ?
                                    <>
                                        <div className="w3-row pw-wt-age">{getCategories(data.ageStages, 'description')}</div>
                                        <div className="w3-row pw-wt-context">{getCategories(data.contexts, 'name')}
                                            {!disableRemove && (
                                                <span className="pw-swt-remove w3-right w3-hide-small"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(evt) => {
                                                            evt.preventDefault();
                                                            evt.stopPropagation();
                                                            bookmarkRemove(data.slug);
                                                        }}
                                                >
                                                    x Remove
                                                </span>
                                            )}
                                        </div>
                                    </>
                                    :
                                    <div className="w3-row pw-wt-context">&nbsp;
                                        {!disableRemove && (
                                            <span className="pw-swt-remove w3-right w3-hide-small"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={(evt) => {
                                                        evt.preventDefault();
                                                        evt.stopPropagation();
                                                        bookmarkRemove(data.slug);
                                                    }}
                                            >
                                                x Remove
                                            </span>
                                        )}
                                    </div>
                                }
                            </div>
                            <div className="pw-wt-buttons w3-right w3-hide-large w3-hide-medium">
                                {(user) && disableRemove &&(
                                    <button style={{ background: 'none', padding: '0px', border: 'none', outline: 'none', cursor: 'pointer' }}
                                        onClick={(evt) => {
                                            evt.preventDefault();
                                            evt.stopPropagation();
                                            bookmarkTip(data.slug);
                                        }}
                                    >
                                        <img src={(bookmarks.indexOf(data.slug) !== -1) ? LikeIconActive : LikeIcon}
                                            className="pw-wt-btn-like"
                                        />
                                    </button>
                                )}

                                <div className={openShare == false ? "w3-show" : "w3-hide"}>
                                    <img src={ShareIcon} className="pw-wt-btn-share" onClick={(evt) => {
                                        evt.preventDefault();
                                        evt.stopPropagation();
                                        setOpenShare(true);
                                    }} />
                                </div>
                                <SocialShare data={openShare} handleSetOpenCls={(someData) => { setOpenShare(someData) }} 
                                    title={data.title} link={`${location.origin}/${(data.acf_wise_tip.type === 'coreFinding') ? 'core-finding' : 'wise-tip'}/${data.slug}`}
                                    subjectTitle={`Check out this ${(data.acf_wise_tip.type === 'coreFinding') ? 'Core Finding' : 'tip'}!`} description={description}
                                />
                            </div>
                        </div>
                    </div>
                </Link> 
            </div>
            </div>
        </>
    )
}

const SavedWiseTips = (props) => {

    const { wiseTips, location } = props;

    const [isFetching, setIsFetching] = useState(false);
    const [bookmarks, setBookmarks] = useState(null);
    const [bookmarkedTips, setBookmarkedTips] = useState([])
    const [openCls, setOpenCls] = useState(false);

    useEffect(() => {
        fetchBookmarks();
    }, []);

    useEffect(() => {
        if (bookmarks && bookmarks.length) {
            let bookmarkList = [];
            bookmarks.forEach(b => bookmarkList.push(b.slug));
            let bookmarkedTips = wiseTips.filter((item) => {
                if (bookmarkList.indexOf(item.slug) !== -1) {
                    return true;
                }
            });
            const sortInSameOrder = (bookmarkedTips, bookmarkList) => {
                const reducedList = bookmarkList.reduce((initialItem, nextItem, idx) => ((initialItem[nextItem] = idx), initialItem), {})
                return bookmarkedTips.sort((a, b) => reducedList[a['slug']] - reducedList[b[['slug']]])
            }
            const getSortedList = sortInSameOrder(bookmarkedTips, bookmarkList);
            setBookmarkedTips(getSortedList);
        }
    }, [bookmarks]);

    const fetchBookmarks = () => {
        setIsFetching(true);
        api.get('/api/v1/user-bookmark/list/wise-tip')
            .then(resp => {
                setIsFetching(false);
                if (resp && resp.success && resp.data) {
                    setBookmarks(resp.data);
                } else {
                    setBookmarks([]);
                }
            });
    }

    const clearBookmarks = () => {
        setIsFetching(true);
        api.post('/api/v1/user-bookmark/clear/wise-tip')
            .then(resp => {
                setIsFetching(false);
                if (resp && resp.success && resp.data) {
                    fetchBookmarks();
                } else {
                    alert("Something went wrong. Please try again later!");
                }
        });
    }

    if (isFetching || !bookmarks) {
        return (
            <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                <div className="pw-loader" />
            </div>
        )
    }

    return (
        <div className="pw-nfm">
            <div className="w3-row pw-swt-header-main">
                <div className="pw-nfm-header w3-right" style={{ cursor: 'pointer' }} onClick={clearBookmarks} >Clear</div>
                <div className="pw-nfm-header w3-left w3-hide-medium w3-hide-large">Saved WiseTips</div>

                {(!bookmarkedTips.length || !bookmarks.length) && (
                    <div className="w3-row w3-hide-medium w3-hide-large">
                        <div className="w3-left pw-swt-add-more" style={{ marginTop: '0px' }}>
                            <Link to="/wise-tips" className="w3-round-xxlarge w3-button w3-margin-bottom" >
                                <div className="pw-swt-addmore-btntxt">
                                    + Add more WiseTips
                                </div>
                            </Link>
                        </div>
                    </div>
                )}
            </div>

            <div className="w3-row pw-swt-main-block">
                <div className="pw-nfm-header w3-hide-small" style={{ textAlign: 'left' }}>Saved WiseTips</div>
                
                {(!bookmarkedTips.length || !bookmarks.length) && (
                    <div className="w3-row w3-left w3-hide-small">
                        <div className="pw-swt-add-more">
                            <Link to="/wise-tips" className="w3-round-xxlarge w3-button w3-margin-bottom" >
                                <div className="pw-swt-addmore-btntxt">
                                    + Add more WiseTips
                                </div>
                            </Link>
                        </div>
                    </div>
                )}

                <div className="w3-row pw-content-scroll" style={{display: 'flex', flexFlow: 'wrap' }}>

                    {bookmarks && bookmarks.length ?
                        bookmarkedTips.map((item, idx) => (
                            <WTCard 
                                data={item} key={idx}
                                fetchBookmarks={fetchBookmarks}
                                location={location}
                            />
                        ))
                        
                    : ''}
                </div>

            </div>

            {(bookmarkedTips.length && bookmarks.length) ?
                <div className="w3-col s12 m12 pw-rf-submit-section">
                    <Link to="/wise-tips" className="w3-round-xxlarge w3-button pw-rf-submit-btn">
                        <div className="pw-rf-sub-btn-text">
                            Check out more tips <span className="pw-rf-sub-btn-arrow" />
                        </div>
                    </Link>
                </div>
                : ''}
        </div>
    )
}

export default SavedWiseTips;