import React, { useState, Fragment, useEffect, useContext } from "react";
import { navigate, graphql, Link } from "gatsby";
import Modal from '../../components/common/Modal';
import SEO from "../../components/seo";
import '../../components/Auth/auth.scss';
import LeftChevronIcon from '../../images/icons/arrow-left.png';
import ArrowRight from '../../images/icons/right-chevron-dark.png';
import UserDetails from "../../components/ProfileForm/UserDetail";
import NewForMe from "../../components/Profile/NewForMe";
import SavedWiseTips from "../../components/Profile/SavedWiseTips";
import SavedStories from "../../components/Profile/SavedStories";
import SavedWiseJourney from "../../components/Profile/SavedWiseJourneys";
import { api } from '../../../utils';
import Logo1 from '../../images/icons/rf-profile.png';
import Logo2 from '../../images/icons/rf-profile-1.png';
import Logo3 from '../../images/icons/rf-profile-3.png';
import Logo4 from '../../images/icons/rf-profile-2.png';
import AVATAR_TYPES from '../../../constants/avatar-types';
import Logout from '../../components/Logout';
import querystring from 'querystring';
import UserContext from '../../context/UserContext';
import PWHeader from '../../components/common/Header';

const UserProfile = ({ location, pageContext: { ageStages, wiseTips, superHonestlyList, wiseSelfList, wiseSpeakList, shReadDurationMap, journeyPage } }) => {

    const userData = useContext(UserContext);

    const [isFetching, setIsFetching] = useState(false);
    const [user, setUser] = useState();
    const [displayLogout, setLogoutDisplay] = useState(false);
    const [selectedOption, setSelectedOption] = useState('new-for-me');
    const [displayMobMenu, setMobMenuDisplay] = useState(false);
    const [duplicateResolve, setDuplicateResolve] = useState(false);

    let imgIndex = null
    const profileIcons = [Logo1, Logo2, Logo3, Logo4]
    AVATAR_TYPES.forEach((card, idx) => {
        if(card.name == (user && user.avatar_type) ? user.avatar_type : '') {
            imgIndex = idx;
        }
    });

    useEffect(() => {
        fetchUserDetail();
        return () => {
            document.documentElement.classList.remove("pw-no-scroll");
        }
    }, []);

    useEffect(() => {
        if (user) {
            if (user.current_step < 5 || !user.phone_number || !user.phone_verified_at) {
                navigate('/auth', { replace: true });
                return;
            }

            if (user.duplicate_user_id && user.duplicate_user_type) {
                setDuplicateResolve(true);
            } else {
                setDuplicateResolve(false);
            }
        }
    }, [user]);

    const fetchUserDetail = () => {
        setIsFetching(true);
        api.get('/api/v1/auth/user')
        .then(resp => {
            setIsFetching(false);
            if (resp && resp.success && resp.data) {
                setUser(resp.data);
            }
            if (resp && !resp.success && resp.status === 401) {
                navigate('/');
            }
        });
    }

    const handleClose = (closeBtn = null) => {
        document.documentElement.classList.remove("pw-no-scroll");
        if (closeBtn) {
            window.history.back();
        }
    }

    useEffect(() => {
        setQueryFilters(location.search.replace('?', ''));
    }, []);

    const handleOption = (option) => {
        setSelectedOption(option)
        const queryStr = querystring.parse(location.search.replace('?', ''));
        queryStr['tab'] = option;
        navigate(`?${querystring.stringify(queryStr)}`, { replace: true });
    }

    const setQueryFilters =  (data) => {
        const queryStr = querystring.parse(data.replace('?', ''));
        for (let key in queryStr) {
            if (typeof queryStr[key] === 'string') {
                if (key === 'tab') {
                    setSelectedOption(queryStr[key])
                } 
            } 
        }
        if(!data){
            setMobMenuDisplay(true)
        }
    }

    const handleMobOption = (option) => {
        handleOption(option)
        closeSideBar()
    }

    const openSideBar = () => {
        setMobMenuDisplay(true)
    }

    const closeSideBar = () => {
        setMobMenuDisplay(false)
    }

    const handleLogout = () => {
        api.get('/api/v1/auth/logout')
        .then(resp => {
            if (resp && resp.success) {
                userData.setUserData(null);
                localStorage.removeItem('accessToken');
                setLogoutDisplay(true);
                const queryStr = querystring.parse(location.search.replace('?', ''));
                if (queryStr['tab']) {
                    delete queryStr['tab'];
                    navigate(`?${querystring.stringify(queryStr)}`, { replace: true });
                }
            } else {
                alert("Something went wrong. Please try again!");
            }
        });
    }

    const handleAccountLink = () => {
        setIsFetching(true);
        api.post('/api/v1/auth/account-link')
        .then(resp => {
            setIsFetching(false);
            if (resp && resp.success && resp.data) {
                if (resp.data.user) {
                    setUser(resp.data.user);
                }
            } else {
                alert("Something went wrong. Please try again!");
            }
        });
    }

    const cancelAccountLink = () => {
        setIsFetching(true);
        api.post('/api/v1/auth/cancel-account-link')
        .then(resp => {
            setIsFetching(false);
            if (resp && resp.success && resp.data) {
                if (resp.data.user) {
                    setUser(resp.data.user);
                }
            } else {
                alert("Something went wrong. Please try again!");
            }
        });
    }

    if (displayLogout) {
        return <Logout wiseTips={wiseTips} superHonestlyList={superHonestlyList} shReadDurationMap={shReadDurationMap} location={location} />
    }

    if (isFetching || !user) {
        return (
            <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                <div className="pw-loader" />
            </div>
        )
    }

    if (duplicateResolve) {
        return (
            <Fragment>
                <SEO title="Landing" />
                <Modal
                    openCls="w3-show"
                    // customClass="w3-animate-top"
                    customStyle={{
                        width: '100%',
                        minHeight: '100vh',
                        padding: 0,
                        margin: 0
                    }}
                    modalStyle={{
                        padding: 0,
                        margin: 0
                    }}
                >
                    <div className="w3-display-container w3-hide-medium w3-hide-large">
                        <div className="pw-modal-cross pw-ul-cross" onClick={() => handleClose('cross')} />
                    </div>

                    <div className="w3-center pw-user-landing pw-ul-background">
                        <div className="w3-hide-small"><PWHeader mode='search'/></div>
                        <div className="pw-rf-usr ">
                            <div className="pw-back-arrow w3-left" onClick={() => openSideBar()}><img src={LeftChevronIcon} className="pw-back-icon" /></div>
                            <div><img loading="lazy" src={profileIcons[`${imgIndex}`]} /></div>
                            {/* <div className="name">Hello, {user.full_name}</div> */}
                            <div className="welcome">Welcome to your ParentWise account</div>


                            <div className="w3-row" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="w3-col l4 m7 s10 pw-link-acc-block w3-center w3-padding-64">
                                    {`An account already exists with the given ${(user.duplicate_user_type == 'phone') ? 'phone number' : 'e-mail address'}:`}<br />
                                    {(user.duplicate_user_type == 'phone') ?
                                        user.phone_number && 'XXXXXX'+ user.phone_number.substring(user.phone_number.length - 4, user.phone_number.length)
                                        :
                                        user.email && user.email.substring(0, user.email.length)
                                    }
                                    <div className="w3-padding-32">Would you like to link it?</div>

                                    <div className="btn-container">
                                        <button className="w3-button" onClick={handleAccountLink}>Yes</button>
                                        <button className="w3-button" onClick={cancelAccountLink}>No</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <SEO title="Landing" />
            <Modal
                openCls="w3-show"
                // customClass="w3-animate-top"
                customClass="pw-home-page"
                customStyle={{
                    width: '100%',
                    minHeight: '100vh',
                    padding: 0,
                    margin: 0
                }}
                modalStyle={{
                    padding: 0,
                    margin: 0
                }}
            >
                <div className="w3-display-container w3-hide-medium w3-hide-large">
                    <div className="pw-modal-cross pw-ul-cross" onClick={() => handleClose('cross')} />
                </div>

                <div className="w3-center pw-user-landing pw-ul-background">
                    <div className="w3-hide-small"><PWHeader mode='search'/></div>
                    <div className="pw-rf-usr ">
                        <div className="pw-back-arrow w3-left" onClick={() => openSideBar()}><img src={LeftChevronIcon} className="pw-back-icon" /></div>
                        <div><img loading="lazy" src={profileIcons[`${imgIndex}`]} /></div>
                        <div className="name">Hello, {user.full_name}</div>
                        {selectedOption != 'new-for-me' ? <div className="welcome">Welcome to your ParentWise account</div> : null}
                        {selectedOption == 'new-for-me' ? <div className="welcome">Here are some ideas to start you off with</div> : null}

                        <div className="w3-row">
                            <div className="w3-col l2 m3 pw-side-bar" style={{ marginTop: '40px' }}>
                                <div className="sidepanel">
                                    <a onClick={() => handleOption('my-details')} style={{ fontWeight: selectedOption == 'my-details' ? '800' : null }}>My Details</a>
                                    <a onClick={() => handleOption('new-for-me')} style={{ fontWeight: selectedOption == 'new-for-me' ? '800' : null }}>New for me</a>
                                    <a onClick={() => handleOption('saved-stories')} style={{ fontWeight: selectedOption == 'saved-stories' ? '800' : null }}>Saved Stories</a>
                                    <a onClick={() => handleOption('saved-wiseTips')} style={{ fontWeight: selectedOption == 'saved-wiseTips' ? '800' : null }}>Saved WiseTips</a>
                                    <a className="w3-hide" onClick={() => handleOption('saved-wiseJourney')} style={{ fontWeight: selectedOption == 'saved-wiseJourney' ? '800' : null }}>Saved WiseJourney</a>
                                    <a onClick={() => handleLogout()}>Log Out</a>
                                </div>
                            </div>
                            <div className="w3-col l8 m9">
                                <div className="pw-mob-side-bar">
                                    <div id="sidepanel" className={"sidepanel " + (displayMobMenu ? "show-sidepanel" : "")}>
                                        <div className="pw-back-arrow w3-left"><img src={LeftChevronIcon} onClick={() => closeSideBar()} className="pw-back-icon" /></div>
                                        <div className="pw-side-menu">
                                            <a onClick={() => handleMobOption('my-details')} style={{ fontWeight: selectedOption == 'my-details' ? '800' : null }}>My Details
                                                <img className="pw-arrow-right" src={ArrowRight} />
                                            </a>
                                            <a onClick={() => handleMobOption('new-for-me')} style={{ fontWeight: selectedOption == 'new-for-me' ? '800' : null }}>New for me
                                                <img className="pw-arrow-right" src={ArrowRight} />
                                            </a>
                                            <a onClick={() => handleMobOption('saved-stories')} style={{ fontWeight: selectedOption == 'saved-stories' ? '800' : null }}>Saved Stories
                                                <img className="pw-arrow-right" src={ArrowRight} />
                                            </a>
                                            <a onClick={() => handleMobOption('saved-wiseTips')} style={{ fontWeight: selectedOption == 'saved-wiseTips' ? '800' : null }}>Saved WiseTips
                                                <img className="pw-arrow-right" src={ArrowRight} />
                                            </a>
                                            <a className="w3-hide" onClick={() => handleMobOption('saved-wiseJourney')} style={{ fontWeight: selectedOption == 'saved-wiseJourney' ? '800' : null }}>Saved WiseJourney
                                                <img className="pw-arrow-right" src={ArrowRight} />
                                            </a>
                                            <a onClick={() => handleLogout()}>Log Out
                                                <img className="pw-arrow-right" src={ArrowRight} />
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {selectedOption == 'my-details' ?
                                    (
                                        <UserDetails 
                                            user={user} ageStages={ageStages} 
                                            setUser={setUser}
                                        />
                                    )
                                    : null}

                                {selectedOption == 'new-for-me' ?
                                    (
                                        <NewForMe
                                            user={user}
                                            wiseTips={wiseTips}
                                            superHonestlyList={superHonestlyList}
                                            shReadDurationMap={shReadDurationMap}
                                            wiseSelfs={wiseSelfList} 
                                            wiseSpeaks={wiseSpeakList}
                                            location={location}
                                        />
                                    )
                                    : null}

                                {selectedOption == 'saved-stories' ?
                                    (
                                        <SavedStories superHonestlys={superHonestlyList} shReadDurationMap={shReadDurationMap} />
                                    )
                                    : null}

                                {selectedOption == 'saved-wiseTips' ?
                                    (
                                        <SavedWiseTips wiseTips={wiseTips} location={location}/>
                                    )
                                    : null}

                                {selectedOption == 'saved-wiseJourney' ?
                                    (
                                        <SavedWiseJourney 
                                            wiseSelfs={wiseSelfList} 
                                            wiseSpeaks={wiseSpeakList} 
                                            groupJourney={journeyPage.acf_wise_journey_page.groupJourney}
                                            onlineJourney={journeyPage.acf_wise_journey_page.onlineJourney}
                                            location={location}
                                        />
                                    )
                                    : null}

                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </Fragment>
    )
}

export const query = graphql`
  {
    cms {
      ageStages {
        nodes {
          description
        }
      }
    }
  }
`

export default UserProfile;