import React, { useState, useEffect } from "react";
import '../../components/Auth/auth.scss';
import LikeIcon from '../../images/icons/like.png';
import ShareIcon from '../../images/icons/share.png';
import SocialShare from '../../components/common/SocialButtons';
import { api } from '../../../utils';
import PWMarkdown from '../../components/common/PWMarkdown';
import Modal from '../../components/common/Modal';
import { Link } from "gatsby";

const WiseJourneyCard = ({ data, groupJourney, onlineJourney, location, fetchBookmarks }) => {
    const [openShare, setOpenShare] = useState(false)
    const [videoData, setVideoData] = useState(null);
    const [openCls, setOpenCls] = useState('w3-hide')
    const acf = data.type === 'wise-self' ? data.acf_wise_self : data.acf_wise_speak;

    const handleClose = () => {
        document.documentElement.classList.remove("pw-no-scroll");
        closeVideoModal();
    }

    const handleVideoModal = () => {
        setVideoData(data);
        setOpenCls('w3-show');
    }

    const closeVideoModal = () => {
        setVideoData(null);
        setOpenCls('w3-hide');
        document.body.classList.remove("pw-no-scroll");
    }

    const getCategories = (data) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let categoryList = [];
        data.nodes.forEach(item => {
            categoryList.push(item.name)
        });
        return categoryList.join(' & ');
    }

    let thumbnail = (acf.video && acf.video.thumbnail && acf.video.thumbnail.sourceUrl) ? acf.video.thumbnail.sourceUrl : null;
    if (!thumbnail) {
        thumbnail = `https://vumbnail.com/${data.videoId}.jpg`;
    }

    const bookmarkRemove = (slug) => {
        const fData = {
            type: 'wise-journey',
            slug
        }
        api.post('/api/v1/user-bookmark/remove', fData)
        .then(resp => {
            if (resp && resp.success) {
                fetchBookmarks();
            } else {
                alert("Something went wrong. Please try again later!");
            }
        });
    }

    return (
        <div className="w3-col m4 s12 pw-swj-card-separate">
            <div style={{ width: '94%' }}>
                <div className="pw-swt-remove w3-right" style={{cursor: 'pointer'}}
                    onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        bookmarkRemove(data.slug);
                    }}>
                    x Remove
                </div>
            </div>

            <div className="pw-swj-block">
                <div className="w3-row pw-swj-header" style={{ borderColor: `${acf.themeColor}`, color: `${acf.themeColor}` }}>
                    <div className="pw-swj-title" >
                        UPCOMING MODULE
                    </div>
                    <div className="pw-swj-subtitle" >
                        {data.title}
                    </div>
                    <div className="pw-swj-subtitle" >
                        ({acf.date}) | {getCategories(data.ageStages)}
                    </div>
                    <div className="pw-wt-buttons" style={{ marginTop: '-20px' }}>
                        <div className="w3-hide">
                            <img src={LikeIcon} className="pw-wt-btn-like" />
                        </div>
                        <div>
                            <img src={ShareIcon} className={`pw-wt-btn-share ${openShare == false ? "w3-show" : "w3-hide"}`} onClick={() => {setOpenShare(true)}} />
                        </div>
                        <SocialShare data={openShare} handleSetOpenCls={(someData) => { setOpenShare(someData) }} 
                            title={data.title} link={`${location.origin}/${data.type}#${data.slug}`} 
                            subjectTitle={'Check out this journey!'} description={acf.description}
                        />
                    </div>
                </div>
                <div className="w3-row pw-swj-body">
                    <div className="w3-col s12">
                        <div className="pw-swj-body-desc">
                            <PWMarkdown data={acf.description} />
                            {/* <div className="w3-hide-medium w3-hide-large"><img className="pw-arrow-right w3-margin-top w3-margin-left" src={ArrowRight} /></div> */}
                        </div>
                    </div>
                </div>
                {acf.video.videoId ?
                    <div style={{ cursor: "pointer", position: "relative" }}>
                        <div className="pw-vc-play-btn" onClick={() => handleVideoModal()} />
                        <img loading="lazy" style={{ width: "100%" }}
                            src={thumbnail}
                            alt="thumbnail"
                            onClick={() => handleVideoModal()}
                            className="pw-ws-img"
                        />
                        {videoData ?
                            <Modal openCls={openCls} closeVideoModal={closeVideoModal} data={videoData} customClass="pw-video-modal">
                                <div className="w3-display-container">
                                    <div
                                        className="pw-video-close"
                                        onClick={() => handleClose()}
                                    >
                                        <div className="pw-close-btn" />
                                    </div>
                                </div>
                                <div className="pw-video-container">
                                    <iframe
                                        src={`https://player.vimeo.com/video/${acf.video.videoId}`}
                                        frameBorder="0"
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        allowFullScreen>
                                    </iframe>
                                </div>
                            </Modal>
                        : ''}
                    </div> :
                    <div className="pw-ws-img">
                        <img loading="lazy" className="pw-ws-image" src={acf.image.sourceUrl} alt="thumbnail" style={{ width: '100%' }} />
                    </div>
                }
                {acf.enableSurvey === 'yes' ? <>
                    {(groupJourney || onlineJourney) ?
                        <div className="pw-swj-text-13" style={{ marginTop: '8px' }}>
                            Sign up now!
                        </div>
                    : null}
                    <div className="w3-row" style={{ marginTop: '8px', display: "flex", justifyContent: "center" }}>
                        {groupJourney ?
                            <div className="w3-col m6 s6 pw-swj-submit-section">
                                <a href={groupJourney} target="_blank" rel="noreferrer" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                                    <div className="pw-cf-sub-btn-text">
                                        Group Journey <span className="pw-cf-sub-btn-arrow" />
                                    </div>
                                </a>
                            </div>
                        : null}
                        {onlineJourney ?
                            <div className="w3-col m6 s6 pw-swj-submit-section">
                                <a href={onlineJourney} target="_blank" rel="noreferrer" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                                    <div className="pw-cf-sub-btn-text">
                                        Online Journey <span className="pw-cf-sub-btn-arrow" />
                                    </div>
                                </a>
                            </div>
                        : null}
                    </div>
                </> : ''}
                <Link to={`/${data.type}#${data.slug}`} className="pw-swj-text-10">
                    See all modules
                </Link>
            </div>
        </div>
    );
}

const SavedWiseJourney = ({ wiseSelfs, wiseSpeaks, groupJourney, onlineJourney, location }) => {

    const [isFetching, setIsFetching] = useState(false);
    const [bookmarks, setBookmarks] = useState(null);
    const [bookmarkedJourneys, setBookmarkedJourneys] = useState([])
    let wiseJourney = wiseSelfs.concat(wiseSpeaks)

    const newBookmarkedJourneys = bookmarkedJourneys.map((item) => {
        if (item.acf_wise_self) {
            return Object.assign({}, item, { type: "wise-self" });
        } else {
            return Object.assign({}, item, { type: "wise-speak" });
        }
    });

    useEffect(() => {
        fetchBookmarks();
    }, []);

    useEffect(() => {
        if (bookmarks && bookmarks.length) {
            let bookmarkList = [];
            bookmarks.forEach(b => bookmarkList.push(b.slug));
            let bookmarkedJourneys = wiseJourney.filter((item) => {
                if (bookmarkList.indexOf(item.slug) !== -1) {
                    return true;
                }
            });
            const sortInSameOrder = (bookmarkedJourneys, bookmarkList) => {
                const reducedList = bookmarkList.reduce((initialItem, nextItem, idx) => ((initialItem[nextItem] = idx), initialItem), {})
                return bookmarkedJourneys.sort((a, b) => reducedList[a['slug']] - reducedList[b[['slug']]])
            }
            const getSortedList = sortInSameOrder(bookmarkedJourneys, bookmarkList);
            setBookmarkedJourneys(getSortedList);
        }
    }, [bookmarks]);

    const fetchBookmarks = () => {
        setIsFetching(true);
        api.get('/api/v1/user-bookmark/list/wise-journey')
            .then(resp => {
                setIsFetching(false);
                if (resp && resp.success && resp.data) {
                    setBookmarks(resp.data);
                } else {
                    setBookmarks([]);
                }
        });
    }

    const clearBookmarks = () => {
        setIsFetching(true);
        api.post('/api/v1/user-bookmark/clear/wise-journey')
            .then(resp => {
                setIsFetching(false);
                if (resp && resp.success && resp.data) {
                    fetchBookmarks();
                } else {
                    alert("Something went wrong. Please try again later!");
                }
        });
    }    

    if (isFetching || !bookmarks) {
        return (
            <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                <div className="pw-loader" />
            </div>
        )
    }

    return (
        <div className="pw-nfm">
            <div className="w3-row pw-swt-header-main">
                <div className="pw-nfm-header w3-right" style={{ cursor: 'pointer' }} onClick={clearBookmarks}>Clear</div>
                <div className="pw-nfm-header w3-left w3-hide-medium w3-hide-large">Saved WiseJourney</div>

                {(!newBookmarkedJourneys.length || !bookmarks.length) && (
                    <div className="w3-row w3-hide-medium w3-hide-large">
                        <div className="w3-left pw-swt-add-more" style={{ marginTop: '0px' }}>
                            <Link to="/wise-journey" className="w3-round-xxlarge w3-button w3-margin-bottom" >
                                <div className="pw-swt-addmore-btntxt">
                                    + Add more WiseJourney
                                </div>
                            </Link>
                        </div>
                    </div>
                )}
            </div>

            <div className="w3-row pw-swt-main-block">
                <div className="pw-nfm-header w3-hide-small" style={{ textAlign: 'left' }}>Saved WiseJourney</div>

                {(!newBookmarkedJourneys.length || !bookmarks.length) && (
                    <div className="w3-row w3-left w3-hide-small">
                        <div className="pw-swt-add-more">
                            <Link to="/wise-journey" className="w3-round-xxlarge w3-button w3-margin-bottom" >
                                <div className="pw-swt-addmore-btntxt">
                                    + Add more WiseJourney
                                </div>
                            </Link>
                        </div>
                    </div>
                )}

                <div className="w3-row pw-swt-scroll">

                    {bookmarks && bookmarks.length ?
                        newBookmarkedJourneys.map((card) =>
                            <WiseJourneyCard
                                data={card}
                                groupJourney={groupJourney}
                                onlineJourney={onlineJourney}
                                location={location}
                                fetchBookmarks={fetchBookmarks}
                            />
                        )
                    : ''}
                </div>
            </div>

            {(newBookmarkedJourneys.length && bookmarks.length) ?
                <div className="w3-row w3-hide-medium w3-hide-large">
                    <div className="w3-col s12 m12 pw-cf-submit-section w3-margin-top">
                        <Link to="/wise-journey" className="w3-round-xxlarge w3-button pw-cf-submit-btn w3-margin-bottom">
                            <div className="pw-cf-sub-btn-text" style={{textDecoration: "none"}}>
                                Find out more <span className="pw-cf-sub-btn-arrow" />
                            </div>
                        </Link>
                    </div>
                </div>
            : ''}

            {(newBookmarkedJourneys.length && bookmarks.length) ?
                <div className="w3-row w3-hide-small w3-center" style={{ marginTop: '8px' }}>
                    <div className="w3-col s4 m4"><br /></div>
                    <div className="w3-col s4 m4 pw-cf-submit-section">
                        <Link to="/wise-journey" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                            <div className="pw-cf-sub-btn-text" style={{textDecoration: "none"}}>
                                Find out more <span className="pw-cf-sub-btn-arrow" />
                            </div>
                        </Link>
                    </div>
                </div>
            : ''}
        </div>
    )
}

export default SavedWiseJourney;