import React, { useEffect, useState, useRef } from "react";
import '../../components/Auth/auth.scss';
import RadioButton from '../../components/common/RadioButton';
import { api } from '../../../utils';
import CircleTick from '../../images/icons/tick-inside-circle.png';

import Emoji1 from '../../images/icons/emoji-1.png';
import Emoji2 from '../../images/icons/emoji-2.png';
import Emoji3 from '../../images/icons/emoji-3.png';
import Emoji4 from '../../images/icons/emoji-4.png';
import Emoji5 from '../../images/icons/emoji-5.png';
import Emoji6 from '../../images/icons/emoji-6.png';
import DropDown from "../common/DropDown";
import PhoneVerification from '../RegisterForm/PhoneVerification';
import Modal from '../common/Modal';

const emojis = [
    { label: 'happy', value: Emoji1 },
    { label: 'joy', value: Emoji2 },
    { label: 'sad', value: Emoji3 },
    { label: 'cry', value: Emoji4 },
    { label: 'angry', value: Emoji5 },
    { label: 'very_angry', value: Emoji6 }
]

const UserDetails = (props) => {

    const { ageStages, user, setUser } = props;

    const [isSending, setIsSending] = useState(false);

    const relationships = [
        { value: 'a parent' },
        { value: 'a grandparent' },
        { value: 'an aunty' },
        { value: 'an uncle' },
        { value: 'a caregiver' },
    ];
    const types = [
        { value: 'full time' },
        { value: 'during working hours' },
        { value: 'after working hours' },
        { value: 'certain days of the week' },
        { value: 'only on weekends' },
    ];
    const numbers = [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }, { value: '5' },
    { value: '6' }, { value: '7' }, { value: '8' }, { value: '9' }, { value: '10' },
    ];

    const topicList = [
        { value: 'Stories' },
        { value: 'Parenting tips' },
        { value: 'Group parenting sessions' },
        { value: 'Parent-related events' },
    ];

    const ageGroupList = ageStages.map((item) => {
        return Object.assign({}, item, { checked: false });
    });
    const initialData = {
        name: (user && user.full_name) ? user && user.full_name : '',
        email: (user && user.email) ? user && user.email : '',
        mobileNo: (user && user.phone_number) ? user.phone_number : '',
        ageStages: ageGroupList,
        topics: topicList,
        relation: (user && user.relationship) ? user.relationship : 'a parent',
        childCount: (user && user.number_of_children) ? user.number_of_children : '1',
        childCareTime: (user && user.child_care_time) ? user.child_care_time : 'full time',
        feeling: (user && user.feeling) ? emojis.find((i) => i.label == user.feeling).value : '',
        subscribe: (user && user.email_subscription_id) ? true : false,
    }
    const [formData, setFormData] = useState(initialData);
    const [errors, setErrors] = useState({});
    const [formSubmitted, setformSubmitted] = useState(false);
    const [showPhoneUpdate, setShowPhoneUpdate] = useState(false);

    useEffect(() => {
        setShowPhoneUpdate(false);
    }, [user])

    const ageGroup = [];
    if (user && user.age_group_interests) {
        ageGroup.push(user && user.age_group_interests.split(", "))
        ageGroupList.forEach(item => {
            ageGroup.forEach(data => {
                data.forEach(months => {
                    if (item.description == months) {
                        item.checked = true;
                    }
                })
            })
            return { ...item };
        });
    }

    const topicGroup = [];
    if (user && user.topic_interests) {
        topicGroup.push(user && user.topic_interests.split(", "))
        topicList.forEach(item => {
            topicGroup.forEach(topics => {
                topics.forEach(eachTopic => {
                    if (item.value == eachTopic) {
                        item.checked = true;
                    }
                })
            })
            return { ...item };
        });
    }

    const handleErrors = (name) => {
        const err = Object.assign({}, errors);
        if (err[name]) {
            err[name] = null;
        }
        setErrors(err);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormData({
            ...formData,
            [name]: value
        });
        handleErrors(name);
    }

    const handleClick = (name, selectData) => {
        let values = null;
        if (name === 'topics') {
            values = formData.topics;
        } else {
            values = formData.ageStages
        }
        const data = values.map((item) => {
            let selectedValue = null;
            let itemValue = null;
            if (name === 'topics') {
                selectedValue = selectData.value;
                itemValue = item.value;
            } else {
                selectedValue = selectData.description;
                itemValue = item.description;
            }
            if (selectedValue === itemValue && !selectData.checked) {
                item.checked = true;
            } else if (selectedValue === itemValue && selectData.checked == item.checked) {
                item.checked = false;
            }
            return { ...item };
        })
        setFormData({
            ...formData,
            [name]: data
        });
        handleErrors(name);
    }

    const handleSelect = (name, selected) => {
        setFormData({
            ...formData,
            [name]: selected
        });
        handleErrors(name);
    }

    const handleClickSubscribe = () => {
        setFormData({
            ...formData,
            subscribe: !formData.subscribe ? true : false,
        });
    }

    const validateData = () => {
        const skipList = [
            'mobileNo', 'subscribe', 'email'
        ];
        let hasErrors = false;
        Object.keys(formData).forEach((key) => {
            const pos = skipList.indexOf(key);
            if (pos < 0) {
                if (!formData[key]) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
            
            if (key == 'ageStages') {
                if (!formData.ageStages.some(item => item.checked)) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
            if (key == 'topics') {
                if (!formData.topics.some(item => item.checked)) {
                    hasErrors = true;
                    errors[key] = 'This field is mandatory';
                }
            }
        });
        if (hasErrors) {
            setErrors({ ...errors });
            return false;
        }

        return formData;
    }

    const handleSubmit = () => {
        const validData = validateData();
        if (!validData) {
            return;
        }

        let topicInt = [];
        let ageGroupInt = [];

        formData.topics.forEach(i => {
            if (i.checked) {
                topicInt.push(i.value)
            }
        });

        formData.ageStages.forEach(i => {
            if (i.checked) {
                ageGroupInt.push(i.description)
            }
        });

        const fData = {
            name: formData.name,
            ageStages: ageGroupInt,
            relationship: formData.relation,
            numberOfChild: formData.childCount,
            childCareTime: formData.childCareTime,
            feeling: emojis.find((i) => i.value === formData.feeling).label,
            topics: topicInt,
            subscribe: formData.subscribe
        }

        updateChanges(fData);
    }

    const updateChanges = (fData) => {
        setIsSending(true);
        api.post('/api/v1/auth/update/user-details', fData)
            .then(resp => {
                setIsSending(false);
                if (resp && resp.success) {
                    setUser(resp.data);
                    setformSubmitted(true)
                    setTimeout(() => {
                        setformSubmitted(false)
                    }, 1500);
                } else {

                }
            });
    }

    const handleEmailUpdate = () => {
        const emailPattern = /^(([^<>()[\]{}'^?\\.,!|//#%*-+=&;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

        let hasErrors = false;
        if (!formData['email']) {
            hasErrors = true;
            errors['email'] = 'This field is mandatory';
        } else {
            if (!emailPattern.test(formData.email)) {
                hasErrors = true;
                errors.email = 'Enter a valid email';
            }
        }

        if (hasErrors) {
            setErrors({ ...errors });
            return false;
        }

        setIsSending(true);
        api.post('/api/v1/auth/update/user-email', { email: formData.email })
        .then(resp => {
            setIsSending(false);
            if (resp && resp.success) {
                alert("A verification email is sent to your email address. Please verify.");
            } else {
                alert("Something went wrong. Please try again!");
                console.log(resp.message);
            }
        });
    }

    const numberValidate = (event) => {
        if (!/[+0-9]/.test(event.key)) {
            event.preventDefault();
        }
    }

    let isEmailVerified = false;
    if (user && user.email_verified_at) {
        isEmailVerified = true;
    }
    if (user && user.email !== formData.email) {
        isEmailVerified = false;
    }

    let isPhoneVerified = false;
    if (user && user.phone_verified_at) {
        isPhoneVerified = true;
        formData.mobileNo = user.phone_number;
    }
    if (user && user.phone_number !== formData.mobileNo) {
        isPhoneVerified = false;
    }

    return (
        <div>
            <div style={{ marginTop: '40px' }}></div>
            <div className="pw-ud-main">
                <div className="header" style={{ marginTop: '0' }}>Feel free to edit any of your details below so your ParentWise account is totally up to date.</div>
                <div className="pw-landing-body">
                    <div className="pw-rf-landing">
                        <div className="pw-rf-input">
                            <input className="w3-input pw-rf-input-val"
                                placeholder="Full Name"
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={(e) => handleChange(e)}
                            />
                            <div className="pw-error-text">{errors.name}</div>
                        </div>
                    </div>
                    <div className="pw-rf-landing">
                        <div className="pw-rf-input">
                            <div style={{ display: 'flex' }}>
                                <input className="w3-input pw-rf-input-val pw-ud-input"
                                    placeholder="Email"
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={(e) => handleChange(e)}
                                    // disabled={(user && user.email_verified_at) ? true : false}
                                />
                                <span className="pw-ud-span-wrap">
                                    <button className="w3-round-xxlarge pw-ud-btn-yellow" disabled={isEmailVerified} onClick={handleEmailUpdate}
                                        style={{minWidth : '110px'}}>
                                        {(isEmailVerified) ? "Email Verified" : "Verify Email"}
                                    </button>
                                </span>
                            </div>
                            <div className="pw-error-text">{errors.email}</div>
                        </div>
                    </div>
                    <div className="pw-rf-radio-btn pw-ud-radio">
                        <RadioButton
                            key={''}
                            label={'I want to receive news and future updates from ParentWise'}
                            labelCls={true}
                            value={formData.subscribe}
                            checked={formData.subscribe}
                            onChange={(e) => handleClickSubscribe(e)}
                            circleCls="pw-rf-radio-circle pw-ud-radio-circle-margin"
                            circleCheckedCls="pw-rf-radio-check-circle"
                            labelCls="pw-radio-label-detail"
                        />
                    </div>
                    <div className="pw-rf-landing">
                        <div className="pw-rf-input">
                            <div style={{ display: 'flex' }}>
                                <input className="w3-input pw-rf-input-val pw-ud-input"
                                    placeholder="Phone Number"
                                    type="text"
                                    name="mobileNo"
                                    value={formData.mobileNo}
                                    onKeyPress={(e) => numberValidate(e)}
                                    onChange={(e) => handleChange(e)}
                                    disabled={true}
                                />
                                <span className="pw-ud-span-wrap">
                                    <button className="w3-round-xxlarge pw-ud-btn-yellow" onClick={() => setShowPhoneUpdate(true)}
                                    style={{minWidth : '124px'}}>
                                        Update Number
                                    </button>
                                </span>
                            </div>
                            <div className="pw-error-text">{errors.mobileNo}</div>
                        </div>
                    </div>


                    <div className="pw-register" style={{ paddingBottom: '16px', marginTop: '30px' }}>
                        <div className="pw-us-detail">
                            <div className="user-sub-block">
                                <div>I am </div> &nbsp;
                                <div className="w3-center" style={{ width: '130px' }}>
                                    <DropDown
                                        placeholder="a parent"
                                        options={relationships}
                                        customCls="pw-cf-input"
                                        selectedValue={formData.relation}
                                        onChange={(e) => handleSelect('relation', e)}
                                        containerCls="option-container-txt"
                                        valueCls="value-selected-small"
                                    />
                                    <div className="pw-error-text">{errors.relation}</div>
                                </div> &nbsp; &nbsp;
                                <div>of</div> &nbsp; &nbsp;
                                <div style={{ width: '70px' }}>
                                    <DropDown
                                        placeholder="1"
                                        options={numbers}
                                        customCls="pw-cf-input"
                                        selectedValue={formData.childCount}
                                        onChange={(e) => handleSelect('childCount', e)}
                                        containerCls="option-container-txt"
                                        valueCls="value-selected-small"
                                    />
                                    <div className="pw-error-text">{errors.childCount}</div>
                                </div>&nbsp; &nbsp;
                            </div>
                            <div style={{marginTop: '12px'}}>
                                child/children. I look after my child/children
                            </div>
                        </div>
                        <div className="pw-us-detail">
                            <div className="user-sub-block">
                                <div style={{ width: '150px' }}>
                                    <DropDown
                                        placeholder="full time"
                                        options={types}
                                        customCls="pw-cf-input"
                                        selectedValue={formData.childCareTime}
                                        onChange={(e) => handleSelect('childCareTime', e)}
                                        containerCls="option-container-txt"
                                        valueCls="value-selected-small"
                                    />
                                    <div className="pw-error-text">{errors.childCareTime}</div>
                                </div> &nbsp; &nbsp;
                                <div>. I feel</div> &nbsp; &nbsp;
                                <div style={{ width: '80px' }}>
                                    <DropDown
                                        placeholder="EMOJIS"
                                        options={emojis}
                                        customCls="pw-cf-input"
                                        selectedValue={formData.feeling}
                                        onChange={(e) => handleSelect('feeling', e)}
                                        emoji={true}
                                        containerCls="option-container-emj"
                                        optionCls="option-emj"
                                    />
                                    <div className="pw-error-text">{errors.feeling}</div>
                                </div>&nbsp; &nbsp;
                            </div>
                            <div className="user-sub-block" style={{marginTop: '12px'}}>
                                <div>about being </div> &nbsp; &nbsp;
                                <div classNme="w3-center" style={{ width: '100px' }}>
                                    <input className="w3-input pw-cf-input"
                                        type="text"
                                        name="relation"
                                        value={formData.relation}
                                        disabled={true}
                                        style={{fontSize : '13px'}}
                                    />
                                    <div className="pw-error-text">{errors.relation}</div>
                                </div> &nbsp; &nbsp;
                                <div>today.</div>
                            </div>
                        </div>
                    </div>
                    <div className="sub-header">My Preferences:</div>
                    <div className="title">Information on parenting children aged:</div>
                    <div className="w3-row radio-button">
                        <div className="w3-col m8 s12">
                            {formData.ageStages.map((item, i) => (
                                <div className="w3-col m4 s12 pw-margin-top-4" key={i}>
                                    <div>
                                        <RadioButton
                                            key={i}
                                            label={item.description}
                                            circleCls="pw-rf-radio-circle"
                                            circleCheckedCls="pw-rf-radio-check-circle"
                                            labelCls="pw-rf-radio-label-1"
                                            value={item.description}
                                            checked={item.checked}
                                            onChange={(e) => handleClick('ageStages', item)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="pw-error-text" style={{ marginLeft: '20px' }}>{errors.ageStages}</div>

                    <div className="title"> What I’m looking forward to finding:</div>
                    <div className="w3-row radio-button">
                        <div className="w3-col m8 s12">
                            {formData.topics.map((item,i) => (
                                <div className="w3-col m6 s12 pw-margin-top-4" key={i}>
                                    <RadioButton
                                        key={''}
                                        label={item.value}
                                        circleCls="pw-rf-radio-circle"
                                        circleCheckedCls="pw-rf-radio-check-circle"
                                        labelCls="pw-rf-radio-label-1"
                                        value={item.value}
                                        checked={item.checked}
                                        onChange={(e) => handleClick('topics', item)}
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="pw-error-text">{errors.topics}</div>
                    </div>

                    <div className="w3-col s12 m12 pw-rf-submit-section pw-save-change">
                        <button className="w3-round-xxlarge w3-button pw-rf-submit-btn" onClick={handleSubmit}>
                            <div className="pw-rf-sub-btn-text">
                                Save Changes
                        </div>
                        </button>
                    </div>

                    {formSubmitted ?
                        <div className="w3-margin-bottom" >
                            <span className="pw-success-popup">
                                <img src={CircleTick} /> Changes saved</span>
                        </div>
                        : null}
                </div>
            </div>

            {(showPhoneUpdate) &&
                <Modal openCls={"w3-show"}
                    customStyle={{ maxWidth: '640px' }}
                    modalStyle={{ zIndex: 50 }}
                >
                    <div className="w3-display-container">
                        <div
                            className="pw-modal-cross pw-ul-cross"
                            style={{ right: '25px', top: '25px' }}
                            onClick={() => setShowPhoneUpdate(false)}
                        />
                    </div>
                    <div className="w3-content">
                        <div className="pw-register" style={{ paddingTop: '50px', paddingBottom: '60px' }}>
                            <PhoneVerification user={user} setUser={setUser} />
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}

export default UserDetails;