import React, { useState, useEffect } from "react";
import { Link } from 'gatsby';
import '../../components/Auth/auth.scss';
import { api } from '../../../utils';

export const StoryCard = (props) => {
    const { data, shReadDurationMap, fetchBookmarks, disableRemove=false, fullWidth=false } = props;

    const bookmarkRemove = (slug) => {
        const fData = {
            type: 'super-honestly',
            slug
        }
        api.post('/api/v1/user-bookmark/remove', fData)
        .then(resp => {
            if (resp && resp.success) {
                fetchBookmarks();
            } else {
                alert("Something went wrong. Please try again later!");
            }
        });
    }

    return ( <>
        {!disableRemove && (
            <div className="w3-hide-medium w3-hide-large pw-ss-mo"
                onClick={(evt) => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    bookmarkRemove(data.slug);
                }}
            >
                x Remove
            </div>
        )}
        <div className={`pw-nfm ${fullWidth ? 'w3-col s12' : 'w3-col l4 m6 s12'}`}>
            <Link to={`/super-honestly/${data.slug}`} className={`pw-story-card row ${!fullWidth ? 'margin' : 'm-top-20'}`}>
                <div className="w3-col m6 s6">
                    <img loading="lazy" src={`${data.acf_super_honestly.thumbnail.sourceUrl}`} className="lo-cardImage" />
                </div>
                <div className="w3-col m6 s6" style={{ marginLeft: "7px" }}>
                    <span className="pw-lo-sub-head" title={data.title}>{data.title}</span>
                    <div className="w3-row">
                        <div className="w3-col m6 pw-lo-text-small">{`${(shReadDurationMap && shReadDurationMap[data.slug]) ? shReadDurationMap[data.slug] : 'x'} min read`}</div>
                        {!disableRemove && (
                            <div className="w3-hide-small w3-col m6 pw-ss-ttread color"
                                onClick={(evt) => {
                                    evt.preventDefault();
                                    evt.stopPropagation();
                                    bookmarkRemove(data.slug);
                                }}
                            >
                                x Remove
                            </div>  
                        )}
                    </div>
                </div>
            </Link>
        </div>
    </>);
}

const SavedStories = (props) => {

    const { superHonestlys, shReadDurationMap } = props;

    const [isFetching, setIsFetching] = useState(false);
    const [bookmarks, setBookmarks] = useState(null);
    const [bookmarkedStories, setBookmarkedStories] = useState([])

    useEffect(() => {
        fetchBookmarks();
    }, []);

    useEffect(() => {
        if (bookmarks && bookmarks.length) {
            let bookmarkList = [];
            bookmarks.forEach(b => bookmarkList.push(b.slug));
            let bookmarkedStories = superHonestlys.filter((item) => {
                if (bookmarkList.indexOf(item.slug) !== -1) {
                    return true;
                }
            });
            const sortInSameOrder = (bookmarkedStories, bookmarkList) => {
                const reducedList = bookmarkList.reduce((initialItem, nextItem, idx) => ((initialItem[nextItem] = idx), initialItem), {})
                return bookmarkedStories.sort((a, b) => reducedList[a['slug']] - reducedList[b[['slug']]])
            }
            const getSortedList = sortInSameOrder(bookmarkedStories, bookmarkList);
            setBookmarkedStories(getSortedList);
        }
    }, [bookmarks]);

    const fetchBookmarks = () => {
        setIsFetching(true);
        api.get('/api/v1/user-bookmark/list/super-honestly')
        .then(resp => {
            setIsFetching(false);
            if (resp && resp.success && resp.data) {
                setBookmarks(resp.data);
            } else {
                setBookmarks([]);
            }
        });
    }

    const clearBookmarks = () => {
        setIsFetching(true);
        api.post('/api/v1/user-bookmark/clear/super-honestly')
            .then(resp => {
                setIsFetching(false);
                if (resp && resp.success && resp.data) {
                    fetchBookmarks();
                } else {
                    alert("Something went wrong. Please try again later!");
                }
        });
    } 

    if (isFetching || !bookmarks) {
        return (
            <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                <div className="pw-loader" />
            </div>
        )
    }

    return (<>
        <div className="pw-saved-stories">
            <div className="w3-row" style={{ marginTop: '8px' }}>
                <div className="w3-col m12 s12 pw-ss-clr w3-hide-small" onClick={clearBookmarks}>Clear</div>
            </div>
            <div className="pw-ss-header w3-hide-small">Saved Stories</div>
            <div className="w3-row w3-hide-medium w3-hide-large" style={{display: "flex", alignItems: "center"}}>
                <div className="w3-col s8 pw-ss-header">Saved Stories</div>
                <div className="w3-col s4 pw-ss-clr" onClick={clearBookmarks}>Clear</div>
            </div>
            {(!bookmarkedStories.length || !bookmarks.length) && (
                <div className="w3-row w3-left">
                    <div className="pw-swt-add-more">
                        <Link to="/super-honestly" className="w3-round-xxlarge w3-button w3-margin-bottom" >
                            <div className="pw-swt-addmore-btntxt">
                                + Add more Stories
                            </div>
                        </Link>
                    </div>
                </div>
            )} 
            <div className="pw-content-scroll" style={{display: 'flex', flexFlow: 'wrap' }}>
                {bookmarks && bookmarks.length ?
                    bookmarkedStories.map((story) => 
                        <StoryCard 
                            data={story} 
                            shReadDurationMap={shReadDurationMap}
                            fetchBookmarks={fetchBookmarks}
                        />
                    )
                : ''} 
            </div>
        </div>
        {(bookmarkedStories.length && bookmarks.length) ? 
            <div className="w3-col s12 m12 pw-rf-submit-section">
                <Link to="/super-honestly" className="w3-round-xxlarge w3-button pw-rf-submit-btn">
                    <div className="pw-rf-sub-btn-text">
                        Read more Stories <span className="pw-rf-sub-btn-arrow" />
                    </div>
                </Link>
            </div>
        : ''}
    </>)
}

export default SavedStories;