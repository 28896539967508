import React, { useState, useEffect } from "react";
import '../Auth/auth.scss';
import { Link } from 'gatsby';
import { StoryCard } from './SavedStories';
import { WTCard } from './SavedWiseTips';
import { api } from '../../../utils';

const NewForMe = (props) => {
    const {
        wiseTips, superHonestlyList, user, location,
        shReadDurationMap, wiseSelfs, wiseSpeaks
    } = props;

    const [bookmarks, setBookmarks] = useState([]);

    const [suggestedTips, setSuggestedTips] = useState(null);
    const [suggestedStories, setSuggestedStories] = useState(null);
    const [suggestedJournies, setSuggestedJournies] = useState(null);

    useEffect(() => {
        if (user) {
          fetchBookmarks();
        }
    }, [user]);
  
    const fetchBookmarks = () => {
        api.get('/api/v1/user-bookmark/list/wise-tip')
        .then(resp => {
          if (resp && resp.success && resp.data && resp.data.length) {
            let bookmarks = [];
            resp.data.forEach(i => bookmarks.push(i.slug));
            setBookmarks(bookmarks);
          } else {
            setBookmarks([]);
          }
        });
    }

    useEffect(() => {
        if (!user || !user.age_group_interests) {
            return;
        }

        const userInterests = user.age_group_interests.split(', ');
        if (wiseTips) {
            let filteredWiseTips = wiseTips.filter((item) => {
                let addToList = false;
                if (item.acf_wise_tip && item.acf_wise_tip.type !== 'wiseTip') {
                    return false;
                }
                if (item.ageStages && item.ageStages.nodes) {
                    item.ageStages.nodes.forEach((i) => {
                        if (userInterests.indexOf(i.description) !== -1) {
                            addToList = true;
                        }
                    })
                }
                if (addToList) {
                    return true;
                }
            });

            setSuggestedTips(filteredWiseTips.sort(() => Math.random() - Math.random()).slice(0, 2));
        }

        if (superHonestlyList) {
            let filteredStories = superHonestlyList.filter((item) => {
                let addToList = false;
                if (item.ageStages && item.ageStages.nodes) {
                    item.ageStages.nodes.forEach((i) => {
                        if (userInterests.indexOf(i.description) !== -1) {
                            addToList = true;
                        }
                    })
                }
                if (addToList) {
                    return true;
                }
            });

            setSuggestedStories(filteredStories.sort(() => Math.random() - Math.random()).slice(0, 3));            
        }

        if (wiseSelfs && wiseSpeaks) {
            let wiseJournies = wiseSelfs.concat(wiseSpeaks);

            let filteredJournies = wiseJournies.filter((item) => {
                let addToList = false;
                if (item.ageStages && item.ageStages.nodes) {
                    item.ageStages.nodes.forEach((i) => {
                        if (userInterests.indexOf(i.description) !== -1) {
                            addToList = true;
                        }
                    })
                }
                if (addToList) {
                    return true;
                }
            });

            setSuggestedJournies(filteredJournies.sort(() => Math.random() - Math.random()).slice(0, 1));   
        }
    }, []);

    const getCategories = (data, key) => {
        if (!data || !data.nodes || !data.nodes.length) {
            return null;
        }
        let categoryList = [];
        data.nodes.forEach(item => {
            categoryList.push(item[key])
        });

        return categoryList.join(', ');
    }

    const [openCls, setOpenCls] = useState(false);

    return (
        <div className="pw-nfm">

            <div className="w3-row" style={{ marginTop: '22px' }}>
                <div className="w3-col l4 m6 s12 pw-nfm-block">

                    <div className="pw-nfm-header w3-hide-small" style={{ textAlign: 'left' }}>Suggested Stories</div>
                    <div className="pw-nfm-header w3-hide-medium w3-hide-large" style={{ textAlign: 'left' }}>Stories for you</div>

                    {(!suggestedStories) && (
                        <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                            <div className="pw-loader" />
                        </div>
                    )}
                     <div className="pw-content-scroll">
                        {(suggestedStories && suggestedStories.length) ?
                            suggestedStories.map(item => (
                                <StoryCard key={item.slug} data={item}
                                    shReadDurationMap={shReadDurationMap}
                                    disableRemove={true}
                                    fullWidth={true}
                                />
                            )
                        ): ''}
                     </div>
                </div>
                <div className="w3-row w3-hide-medium w3-hide-large" style={{ marginBottom: '16px' }}>
                    <div className="pw-cf-submit-section ">
                        <Link to="/super-honestly" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                            <div className="pw-cf-sub-btn-text">
                                Read more stories <span className="pw-cf-sub-btn-arrow" />
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w3-col l4 m6 s12 pw-nfm-block w3-margin-bottom">
                    <div className="pw-nfm-header w3-hide-small" style={{ textAlign: 'left' }}>Suggested WiseTips</div>
                    <div className="pw-nfm-header w3-hide-medium w3-hide-large" style={{ textAlign: 'left' }}>Try these tips</div>

                    {(!suggestedTips) && (
                        <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                            <div className="pw-loader" />
                        </div>
                    )}
                    <div className="pw-content-scroll">
                        {(suggestedTips && suggestedTips.length) ?
                            suggestedTips.map(item => (
                                <WTCard key={item.slug} data={item}
                                    disableRemove={true}
                                    fullWidth={true}
                                    user={user}
                                    bookmarks={bookmarks}
                                    fetchBookmarks={fetchBookmarks} 
                                    location={location}
                                />
                            )
                        ): ''}
                    </div>
                </div>
                <div className="w3-row w3-hide-medium w3-hide-large" style={{ marginBottom: '16px' }}>
                    <div className="pw-cf-submit-section ">
                        <Link to="/wise-tips" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                            <div className="pw-cf-sub-btn-text">
                                Check out more tips <span className="pw-cf-sub-btn-arrow" />
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="w3-hide w3-col l4 m6 s12 pw-nfm-block w3-margin-bottom" style={{ borderRight: 'none' }}>
                    <div className="pw-nfm-header w3-hide-small" style={{ textAlign: 'left' }}> Suggested WiseJourney</div>
                    <div className="pw-nfm-header w3-hide-medium w3-hide-large" style={{ textAlign: 'left' }}>Check out this WiseJourney</div>

                    {(!suggestedJournies) && (
                        <div className="w3-center w3-padding-32" style={{ minHeight: '100vH' }}>
                            <div className="pw-loader" />
                        </div>
                    )}
                    <div className="pw-content-scroll">
                        {(suggestedJournies && suggestedJournies.length) ?
                            suggestedJournies.map(item => {
                                let themeColor = '#ff4e68';
                                let type = '';
                                if (item.acf_wise_self) {
                                    themeColor = item.acf_wise_self.themeColor;
                                    type = 'wise-self';
                                } else if (item.acf_wise_speak) {
                                    themeColor = item.acf_wise_speak.themeColor;
                                    type = 'wise-speak';
                                }
                                return (
                                    <Link to={`/${type}/#${item.slug}`} style={{textDecoration : 'none'}}>
                                        <div className="pw-nfm-wiseJourney-crd">
                                        <div className="w3-row pw-wt-header" style={{ borderBottomColor: themeColor }}>
                                            <div className="pw-wt-title" style={{ color: themeColor }}>
                                                {item.title}
                                            </div>
                                            {(item.acf_wise_self) && (
                                                <div className="pw-wt-subtitle" style={{ color: themeColor }}>
                                                    ({item.acf_wise_self.date}) | {getCategories(item.ageStages, 'name')} 
                                                </div>
                                            )}
                                            {(item.acf_wise_speak) && (
                                                <div className="pw-wt-subtitle" style={{ color: themeColor }}>
                                                    ({item.acf_wise_speak.date}) | {getCategories(item.ageStages, 'name')} 
                                                </div>
                                            )}
                                        </div>

                                        {(item.acf_wise_self && item.acf_wise_self.sessions) && (
                                            item.acf_wise_self.sessions.map((i, idx) => (
                                                <div style={{ marginTop: '8px', textAlign: 'left' }}>
                                                    <div className="pw-nfm-text-14" style={{ color: themeColor }}>
                                                        Session {idx + 1}
                                                    </div>
                                                    <div className="pw-nfm-text-small" style={{ fontSize: '16px' }}>
                                                        {i.title}
                                                    </div>
                                                </div>
                                            ))
                                        )}

                                        {(item.acf_wise_speak && item.acf_wise_speak.sessions) && (
                                            item.acf_wise_speak.sessions.map((i, idx) => (
                                                <div style={{ marginTop: '8px', textAlign: 'left' }}>
                                                    <div className="pw-nfm-text-14" style={{ color: themeColor }}>
                                                        Session {idx + 1}
                                                    </div>
                                                    <div className="pw-nfm-text-small" style={{ fontSize: '16px' }}>
                                                        {i.title}
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                        </div>
                                    </Link>
                                )
                            }
                        ): ''}
                    </div>
                </div>
                <div className="w3-hide w3-row w3-hide-medium w3-hide-large" style={{ marginBottom: '16px' }}>
                    <div className="pw-cf-submit-section ">
                        <Link to="/wise-journey" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                            <div className="pw-cf-sub-btn-text">
                                Find out more <span className="pw-cf-sub-btn-arrow" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="w3-row w3-hide-small" style={{ marginTop: '16px' }}>
                <div className="w3-col s4 m4 pw-cf-submit-section">
                    <Link to="/super-honestly" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                        <div className="pw-cf-sub-btn-text">
                            Read more stories <span className="pw-cf-sub-btn-arrow" />
                        </div>
                    </Link>
                </div>

                <div className="w3-col s4 m4 pw-cf-submit-section">
                    <Link to="/wise-tips" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                        <div className="pw-cf-sub-btn-text">
                            Check out more tips <span className="pw-cf-sub-btn-arrow" />
                        </div>
                    </Link>
                </div>

                <div className="w3-hide w3-col s4 m4 pw-cf-submit-section">
                    <Link to="/wise-journey" className="w3-round-xxlarge w3-button pw-cf-submit-btn" >
                        <div className="pw-cf-sub-btn-text">
                            Find out more <span className="pw-cf-sub-btn-arrow" />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default NewForMe;